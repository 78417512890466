import RestService from "./RestService";
import WalletService from "./WalletService";
import Address from "./AddressService";
import Billing from "./BillingService";
import Bank from "./BankService";
import Password from "./PasswordService";
import Username from "./UsernameService";
import { joinPath } from "../utils/helpers";

const API_ROOT = import.meta.env.REACT_APP_API_URL;
class TransactionsService extends RestService {
    constructor(parentService) {
        if (parentService) {
            super(`${parentService.baseUrl}/transactions`);
        }
        else
            throw new Error("Can be only nested service");
    }

    list(year, month, transactionType, showPurchases, showDeposits) {
        return this.get(`${this.baseUrl}?year=${year}&month=${month}&transactionType=${transactionType}&showPurchases=${showPurchases?"1":"0"}&showDeposits=${showDeposits?"1":"0"}`);
    }

    details(year, month, transactionType) {
        return this.get(`${this.baseUrl}/details?year=${year}&month=${month}&transactionType=${transactionType}`,);
    }


    orderDetails(orderId) {
        return this.get(`${this.baseUrl}/details/${orderId}`,);
    }


    tracking(year, month, transactionType) {
        return this.get(`${this.baseUrl}/tracking?year=${year}&month=${month}&transactionType=${transactionType}`,);
    }


    pendingeWallet() {
        return this.get(`${this.baseUrl}/pendingewallet`,);
    }


    export(year, month, transactionType, showPurchases, showDeposits) {
        return this.get(`${this.baseUrl}/export?year=${year}&month=${month}&transactionType=${transactionType || -1}&showPurchases=${showPurchases?"1":"0"}&showDeposits=${showDeposits?"1":"0"}`);
    }


}

class RegistrationService extends RestService {
    constructor(parentService, parentId) {
        if (parentService) {

            super(`${parentService.baseUrl}/registration`)

        }
        else
            throw new Error("Can be only nested service")
    }

}

export default class UserProfile extends RestService {
    constructor() {
        super('/api/userprofile');
    }

    declineEnroll() {
        return this.put("/api/enroll/customer/invite/declined");
    }

    async getUserACHDisabledStatus() {
        return this.get(`${this.baseUrl}/ach/status`);
    }

    async getUserCardDisabledStatus() {
        return this.get(`${this.baseUrl}/card/status`);
    }

    async acceptSparkWalletTerms() {
        return this.post(`${this.baseUrl}/terms/spark`, { hasAgreed: true });
    }

    async getSparkWalletTermsStatus() {
        return this.get(`${this.baseUrl}/terms/spark`);
    }

    async getWalletStats() {
        return this.get(`/api/userprofile/wallet/stats`);
    }

    async checkIdentityVerification() {
        return this.get(`/api/v2/userprofile/accountStatus`);
    }

    async verificationLinkUrl() {
        return this.get(`/api/v2/userprofile/accountStatus/verificationLinkUrl`);
    }

    Wallet() {
        return new WalletService(this);
    }

    Transactions() {
        return new TransactionsService(this);
    }

    update(entity) {
        return this.put(`${this.baseUrl}/`, entity);
    }

    deleteSpouse() {
        return this.del(`${this.baseUrl}/account/spouse`);
    }

    updateLanguage(preferredLanguage) {
        return this.put(`${this.baseUrl}/language`, {preferredLanguage} )
    }

    updateAutoRenewal(autoRenewalValue) {
        return this.put(`${this.baseUrl}/autorenewal`, {autoRenewalValue} )
    }

    updateProps(props) {
        return this.put(`${this.baseUrl}/props`, {props} )
    }

    AddressService(){
        return new Address(this)
    }

    BillingService() {
        return new Billing(this);
    }
    BankService() {
        return new Bank(this);
    }

    PasswordService() {
        return new Password(this);
    }

    UsernameService() {
        return new Username(this);
    }

    RegistrationService(){
        return new RegistrationService(this)
    }
}
