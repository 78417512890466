import {createTheme} from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#C27C2A'
        },
    },
});

export default theme