import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { lineClamp } from "../../../utils/styles";
import {getProductImage, joinPath} from "../../../utils/helpers";
import {useShopType} from "../useShopType";



const useStyles = makeStyles((theme) => ({
  categoryViewOwl: {
    height: 280,
    [theme.breakpoints.down('sm')]: {
      height: 175,
    },
    textAlign: "center",

  },
  item: {
    height: 240,
    [theme.breakpoints.down('sm')]: {
      height: 125,
    },
    borderRadius: "5%",
    paddingBottom: 10,
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  productTitle: {
    lineClamp: {
      margin: "5px 0",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "2 !important",
      overflow: "hidden",
      lineClamp: 2,
      [theme.breakpoints.down('sm')]: {
        lineClamp: 3,
      },
      textOverflow: "ellipsis",
      whiteSpace: "normal",
      lineHeight: "1.3em",
      textAlign: "center"
    },
    fontSize: "1.0em",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.8em",
    },
    paddingTop: 5,
    color: "#fff",
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
    lineHeight: 1
  },
  link: {
    textDecoration: "none"
  }
}));



const CustomCarousel = ({ products, parentCategoryID }) => {
  const classes = useStyles();
  const shopParams = useShopType()

  if (!products) return ('');

  const options = {
    items: 4,
    loop: false,
    stagePadding: 25,
    center: false,
    rewind: true,
    autoplay: false,
    margin: 15,
    responsive: {
      0: {
        items: 3,
      },
      350: {
        items: 3,
      },
      750: {
        items: 4,
      },
    },
  };

  return (
    <OwlCarousel options={options}>
      {
        products.map((product, i) => {
          let id = parentCategoryID;
          if (!id) {
            id = product.parentCategoryID;
          }
          let productId = product.productCategoryID;
          let link = shopParams.shopId === "autoship"
            ? joinPath(shopParams.home, `/category/${product.productCategoryID}/product-detail/${product.productSKU}`)
            : joinPath(shopParams.home, `/category/${id}/sub-category/${productId}/product-detail/${product.productSKU}`);


          return (
            <div className={classes.categoryViewOwl} key={id + product.productSKU + productId}>
              <Link className={classes.link} to={link}>
                <div style={{ backgroundImage: `url(${ getProductImage(product) })`, backgroundSize: "cover", backgroundPosition: "center" }} className={classes.item}></div>
                <Typography variant={"body1"} className={classes.productTitle}>{product.productName}</Typography>
              </Link>
            </div>
          );
        })
      }
    </OwlCarousel>
  );
};

export default CustomCarousel;