import { makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, {useEffect, useMemo, useState} from "react";
import { useStores } from "../hooks/use-stores";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 0,
  },
  message: { margin: 0 },
  infoMessageWrapper: {
    width: "100%",
    borderRadius: "10px",
    margin: `${theme.spacing(2)}px 0`,
    padding: "20px",
    paddingLeft: "16px",
    position: "relative",
  },
  closeInfoMessage: {
    background: "transparent",
    padding: 0,
    border: "none",
    position: "absolute",
    fontSize: "20px",
    lineHeight: 1,
    top: "4px",
    right: "8px",
    color: "white",
  },
}));

export default observer(({ section = "" }) => {
  const classes = useStyles();
  const [criticalMessage, setCriticalMessage] = useState(null);

  const { commonStore, criticalMessageStore } = useStores();
  const { criticalMessageInfo, criticalMessageLoading } = criticalMessageStore;
  const {language} = commonStore;

  // load forms list at initial load and re-load after filter
  const loadData = () => {
    if (!criticalMessageLoading) {
      void criticalMessageStore.loadData();
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (criticalMessageInfo) {
      const target = criticalMessageInfo.find(
        (item) => item.section === section
      );
      if (target) {
        setCriticalMessage(target);
      }
    }
  }, [criticalMessageInfo, section]);

  const title = useMemo(() => {
    if (criticalMessage) {
      const key =  `title${language.charAt(0).toUpperCase() + language.slice(1)}`;
      return criticalMessage[key] || criticalMessage.titleEn;
    }
    return "";
  }, [criticalMessage, language]);

  const message = useMemo(() => {
    if (criticalMessage) {
      const key =  `message${language.charAt(0).toUpperCase() + language.slice(1)}`;
      return criticalMessage[key] || criticalMessage.messageEn;
    }
    return "";
  }, [criticalMessage, language]);


  return criticalMessage && section ? (
    <div
      className={classes.infoMessageWrapper}
      style={{ backgroundColor: criticalMessage?.color }}
    >
      <button
        className={classes.closeInfoMessage}
        onClick={() => setCriticalMessage(null)}
      >
        x
      </button>
      <h3
        className={classes.title}
        dangerouslySetInnerHTML={{ __html: title ?? "" }}
      />
      <p
        className={classes.message}
        dangerouslySetInnerHTML={{ __html: message ?? "" }}
      />
    </div>
  ) : null;
});
