import { makeAutoObservable } from "mobx";
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class WebTextStore {
  webTextInfoLoading = null;
  webTextInfo = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError(error, type = "webTextError") {
    error = extractErrorMessage(error);
    this.error = error;
  }

  getWebTextByLanguageAndId = generateLoadEntity(
    "webTextInfo",
    this,
    "webTextInfoLoading",
    (params) => {
      return services.WebTextService.deepFetch(params);
    },
    "webTextInfo"

  );
}

export default WebTextStore;
