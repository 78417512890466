import React, {useEffect, useMemo, useState} from 'react';
import {observer} from "mobx-react";
import {useStores} from "../../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    TextField, Typography
} from "@material-ui/core";
import {ContentPageStyles} from "../RoyalStarfish/styles";
import Html from "../../components/Html";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import UplineSearch from "../Members/controls/UplineSearch";
import {useTranslation} from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";

const docId = "6537e8b5c5cab33c04ef0106";

const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),
    pageLoading: {
        paddingTop: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    page: {
        paddingBottom: "32px",
    },

}));

const translationPrefix ='global'
const showExpDate =true
function PurchaseTickets(){

    const classes = useStyles();
    const { authStore, ticketStore, webTextStore, statStore, userBillingInfoStore } = useStores();
    const { currentUser } = authStore;
    const { t } = useTranslation();
    const { webTextInfo, webTextInfoLoading } = webTextStore;
    const { eventsForPurchase, eventsForPurchaseLoading, costPerTicket, costPerTicketLoading, ticketsToBuy, placingOrder, placeOrderError } = ticketStore;

    const [country, setCountry] = React.useState(authStore.country);
    const [selectedTarget, setSelectedTarget] = React.useState("myself");
    const [selectedEvent, setSelectedEvent ] = React.useState(null);
    const [qty, setQty ] = React.useState(1);
    const [ticketAdded, setTicketAdded ] = React.useState(false);
    const [ticketEditing, setTicketEditing ] = React.useState(false);
    const [ticketAdding, setTicketAdding ] = React.useState(true);
    const [_firstName, setFirstName] = useState( "");
    const [_lastName, setLastName] = useState( "");
    const [zip, setZip] = useState( "");
    const [_email, setEmail] = useState("");
    const [paymentID, setPaymentID] = useState("");
    const [_memberId, setMemberId] = useState(null);
    const { eWalletBalance } = statStore;
    const { cards:creditCards, allLoading } = userBillingInfoStore;
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [congrats, setCongrats] = useState(false);

    const [firstNameError, setFirstNameError] = useState( "");
    const [lastNameError, setLastNameError] = useState( "");
    const [emailError, setEmailError] = useState( "");


    const cards = useMemo(()=>{
        return [ {paymentID: "ewallet", ewallet:true, balance:eWalletBalance}, ...creditCards]
    },[creditCards, eWalletBalance])

    let selectedCard = cards && cards.find(x => x.paymentID === paymentID);

    const changeEvent =(e)=>{
        setSelectedEvent( e.target.value)
    }

    const changeCountry =(e)=>{
        setCountry( e.target.value)
    }
    const changeTarget =(e)=>{
        setSelectedTarget( e.target.value)
        if ( e.target.value === "myself_spouse" ) {
            setQty(2)
        }
        else setQty(1)
        setFirstNameError("");
        setLastNameError("");
        setEmailError("");
    }

    const placeOrder= ()=>{
        void (async ()=>{

            const result = await ticketStore.placeOrder(paymentID)
            if ( result && result.success ){
                setCongrats(true);
            }
        })();
    }

    useEffect(() => {
        ticketStore.clearTickets();
        if (!webTextInfoLoading) {
            void webTextStore.getWebTextByLanguageAndId([
                currentUser.preferredLanguage,
                docId,
                'eventsPurchaseTickets'
            ]);
        }
        void userBillingInfoStore.loadAll();
    }, []);

    useEffect(() => {
        void ticketStore.loadEventsForTicketPurchase({ country } );
    }, [country]);


    useEffect(() => {
        if ( selectedEvent ) {
            const event = eventsForPurchase.find((event) => event.EventID === selectedEvent);

            void ticketStore.loadCost({eventCategory: event.startDateConvention? 52: 4, eventID: selectedEvent, country});
        }

    }, [selectedEvent]);

    const nameEditable = useMemo(() => {
        return selectedTarget === "guest";
    }, [selectedTarget]);

    const haveSpouse = useMemo(() => {
        return currentUser.spouse && currentUser.spouse.email !== "" && currentUser.spouse.email != null;
    }, [currentUser])


    const namePrefix = useMemo(() => {
       if (selectedTarget === "myself" || selectedTarget === "myself_spouse") return "";
         if (selectedTarget === "spouse") return "Spouse ";
         if (selectedTarget === "guest") return "My Guest ";
         if (selectedTarget === "member") return "Downline Member ";
         if (selectedTarget === "customer") return "Registered Customer ";
    }, [selectedTarget]);
    const firstName = useMemo(() => {
        if (selectedTarget === "myself" ) return currentUser.firstName;
        if (selectedTarget === "myself_spouse") return currentUser.firstName;
        if (selectedTarget === "spouse") return currentUser.spouse.firstName;
        if (selectedTarget === "guest") return _firstName;
        if (selectedTarget === "member") return _firstName;
        if (selectedTarget === "customer") return _firstName;
    }, [selectedTarget, _firstName]);
    const lastName = useMemo(() => {
        if (selectedTarget === "myself" ) return currentUser.lastName;
        if (selectedTarget === "myself_spouse") return currentUser.lastName;
        if (selectedTarget === "spouse") return currentUser.spouse.lastName;
        if (selectedTarget === "guest") return _lastName;
        if (selectedTarget === "member") return _lastName;
        if (selectedTarget === "customer") return _lastName;
    }, [selectedTarget, _lastName]);
    const email = useMemo(() => {
        if (selectedTarget === "myself" ) return currentUser.email;
        if (selectedTarget === "myself_spouse") return currentUser.email;
        if (selectedTarget === "spouse") return currentUser.spouse.email;
        if (selectedTarget === "guest") return _email;
        if (selectedTarget === "member") return _email;
        if (selectedTarget === "customer") return _email;
    }, [selectedTarget, _email]);
    const memberId = useMemo(() => {
        if (selectedTarget === "myself" ) return currentUser.userID;
        if (selectedTarget === "myself_spouse") return currentUser.userID;
        if (selectedTarget === "spouse") return currentUser.spouse.userID;
        if (selectedTarget === "guest") return currentUser.spouse.userID;
        if (selectedTarget === "member") return _memberId;
        if (selectedTarget === "customer") return _memberId;
    }, [selectedTarget, _memberId]);
    const minTickets = useMemo(() => {
        if (selectedTarget === "myself" ) return 1;
        if (selectedTarget === "myself_spouse") return 2;
        if (selectedTarget === "spouse") return 1;
        if (selectedTarget === "guest") return 1;
        if (selectedTarget === "member") return 1;
        if (selectedTarget === "customer") return 1;
    }, [selectedTarget]);
    const maxTickets = useMemo(() => {
         return 2;
    }, [selectedTarget]);


    const addAnotherTicket = ()=>{
        setSelectedEvent(null)
        setSelectedTarget("myself")
        setQty(1)
        setFirstName("")
        setLastName("")
        setEmail("")
        setZip("")
        setTicketAdding(true)
    }

    const cancelAnotherTicket = ()=>{
        setSelectedEvent(null)
        setSelectedTarget("myself")
        setQty(1)
        setFirstName("")
        setLastName("")
        setEmail("")
        setZip("")
        setTicketAdding(false)
        setTicketEditing(false)
    }

    const addTicket = ()=>{
        if( eventsForPurchase === "guest" && (!firstName) ){
            setFirstNameError("First Name is required");
        }
        if( eventsForPurchase === "guest" && (!lastName) ){
            setLastNameError("Last Name is required");
        }
        if( eventsForPurchase === "guest" && (!email) ){
            setEmailError("Email is required");
        }

        if (!( firstName &&  lastName && email )) return

        const event = eventsForPurchase.find((event) => event.EventID === selectedEvent);
        if ( ticketEditing!==false ){
            ticketStore.updateTicket(ticketEditing, {
                eventName: event.conventionTitle ? event.conventionTitle : `Seminar - ${moment.utc(event.StartDate).format("MMMM")} - ${country === "US" ? "United States" : "Canada"}`,
                eventType: event.conventionTitle ? 52 : 4,
                eventCountry: country,
                eventID: selectedEvent,
                eventStartDate: event.StartDate,
                lifeNumber: memberId,
                firstName: firstName,
                lastName: lastName,
                email: email,
                qty: qty,
                sponsorLifeNumber: currentUser.userID,
                cost: costPerTicket*qty,
                attendee: selectedTarget
            })

        }
        else {
            ticketStore.addTicket({
                eventName: event.conventionTitle ? event.conventionTitle : `Seminar - ${moment.utc(event.StartDate).format("MMMM")} - ${country === "US" ? "United States" : "Canada"}`,
                eventType: event.conventionTitle ? 52 : 4,
                eventID: selectedEvent,
                eventCountry: country,
                eventStartDate: event.StartDate,
                lifeNumber: memberId,
                firstName: firstName,
                lastName: lastName,
                email: email,
                qty: qty,
                sponsorLifeNumber: currentUser.userID,
                cost: costPerTicket*qty,
                attendee: selectedTarget
            })
        }
        setTicketAdded(true)
        setTicketAdding(false)
        setTicketEditing(false)

        if ( (totalCost +costPerTicket*qty) < eWalletBalance ){
            setPaymentID("ewallet")
        }

    }

    const deleteTicket = (index)=>()=>{
        ticketStore.deleteTicket(index)
        if ( ticketsToBuy.length === 0 ) setTicketAdding(true)
    }

    const editTicket = (index)=>()=>{
        const ticket = ticketsToBuy[index];
        setSelectedEvent(ticket.eventID)
        setSelectedTarget(ticket.attendee)
        setCountry(ticket.eventCountry)
        setQty(ticket.qty)
        setFirstName(ticket.firstName)
        setLastName(ticket.lastName)
        setEmail(ticket.email)
        setTicketEditing(index)
    }
    const handleUserSelectFromSearch = (user) => {
        setFirstName(user.FirstName);
        setLastName(user.LastName);
        setEmail(user.Email);
        setMemberId(user.UserID)
    }


    const handleExisting = (e) => {
      setPaymentID(e.target.value);
    };

    const totalCost = useMemo(() => {
        return ticketsToBuy.reduce((acc, ticket) => acc + ticket.cost, 0);
    })

    const notSufficientFunds = useMemo(() => {
        if ( totalCost > eWalletBalance && paymentID === "ewallet" ){
            const isRoyal = authStore.subscriptions?.hasSuperAppPlus;
            const prefix = isRoyal? "/royalstarfish" : "/starfish"
            return <>{t("not_enough_ewallet")} <Link target={"_blank"}
                                                     href={`${prefix}/add_more_money`}>
                <Button size={"small"} color={"primary"} variant={"contained"}>{t("add_money")}</Button></Link></> ;
        }
        else return  null
    })

    const isValid = useMemo(() => {
        return selectedEvent && selectedTarget && qty && firstName && lastName && email;

    },[ selectedEvent, selectedTarget, qty, firstName, lastName, email]);


    const handleFirstNameChange = (event)=>{
        setFirstName(event.target.value);
        setFirstNameError( event.target.value? "": "First Name is required");
    }
    const handleLastNameChange = (event)=>{
        setLastName(event.target.value);
        setLastNameError( event.target.value? "": "Last Name is required");
    }
    const handleEmailChange = (event)=>{
        setEmail(event.target.value);
        if ( !event.target.value ){
            setEmailError(  "Email is required");
        }
        else
        {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if ( !emailRegex.test(event.target.value) ){
                setEmailError("Invalid Email");
            }
            else{
                setEmailError("");
            }
        }


    }

    const startAgain = (event)=>{
        setCongrats(false);
        setTicketAdded(false);
        setTicketAdding(true);
        setTicketEditing(false);
        setSelectedEvent(null)
        setSelectedTarget("myself")
        setQty(1)
        setFirstName("")
        setLastName("")
        setEmail("")
        setZip("")
        ticketStore.clearTickets();

    }


    if (webTextInfoLoading||eventsForPurchaseLoading) {
        return (
            <div className={classes.pageLoading}>
                <CircularProgress size={90} />
            </div>
        );
    }
    if ( congrats )
        return (<Box className={classes.page} minHeight={ 500}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"} >
            <Typography variant={"h5"}> {t("shoppingBoss:complete.congrats")}</Typography>
            <Button variant="contained" color="primary" onClick={startAgain}>Back</Button>
        </Box>)

    return <>
        {!ticketAdded &&  <div className={classes.page}>
            <Html __html={webTextInfo?.text} />
        </div>}
        {ticketsToBuy.length>0 && <div className={classes.page}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Event</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Cost</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ticketsToBuy.map((ticket, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Button variant={"contained"} color={"primary"} size={"small"} onClick={editTicket(index)}>Edit</Button>{" "}
                                <Button variant={"contained"} color={"secondary"} size={"small"} onClick={deleteTicket(index)}>Delete</Button>
                            </TableCell>
                            <TableCell>{ticket.eventName}</TableCell>
                            <TableCell>{ticket.firstName} {ticket.lastName} {(ticket.lifeNumber)}</TableCell>
                            <TableCell>{ticket.email}</TableCell>
                            <TableCell>{ticket.qty}</TableCell>
                            <TableCell>{ticket.cost}</TableCell>
                        </TableRow>))}
                </TableBody>
            </Table>
        </div>}


        {(ticketAdding ||ticketEditing!==false)
            ? <Grid container spacing={3}>
                 <Grid  container item xs={12} md={6} spacing={3}>
                    <Grid item xs={12} sm={4} alignItems={"center"} container>
                        <InputLabel htmlFor="country">Country</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Select label={"Country"} fullWidth
                                inputProps={{
                                    name: 'country',
                                    id: 'country',
                                }}
                                variant={"outlined"}
                                value={country}
                                onChange={changeCountry}>
                            <MenuItem value={"US"}>United States</MenuItem>
                            <MenuItem value={"CA"}>Canada</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} alignItems={"center"} container>
                        <InputLabel htmlFor="events">Event</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <Select
                            fullWidth
                                inputProps={{
                                    name: 'event',
                                    id: 'events',
                                }}
                                variant={"outlined"}
                                value={selectedEvent}
                                onChange={changeEvent}>
                                {eventsForPurchase.map((event) => (
                                    <MenuItem key={event.EventID} value={event.EventID}>
                                        {event.conventionTitle
                                            ?<>{event.conventionTitle}</>
                                            :<>Seminar - {moment.utc(event.StartDate).format("MMMM")} - {country === "US"?"United States":"Canada" } </> }

                                    </MenuItem>
                                ))}
                            </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} alignItems={"center"} container>
                        <InputLabel htmlFor="target">Purchase Ticket For</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <Select     fullWidth            variant={"outlined"}
                                    inputProps={{
                                        name: 'event',
                                        id: 'target',
                                    }}
                                    value={selectedTarget}
                                    onChange={changeTarget}>

                                <MenuItem  value={"myself"}>
                                    Myself
                                </MenuItem>
                            {haveSpouse &&<MenuItem  value={"spouse"}>
                                Spouse
                            </MenuItem>}
                            {haveSpouse && <MenuItem  value={"myself_spouse"}>
                                Myself and Spouse
                            </MenuItem>}
                            <MenuItem  value={"guest"}>
                                Guest (Has no Account Number)
                            </MenuItem><MenuItem  value={"member"}>
                                Downline Member
                            </MenuItem><MenuItem  value={"customer"}>
                                Registered Customer (Yours or Downline)
                            </MenuItem>
                        </Select>
                    </Grid>

                     {(selectedTarget === "member" || selectedTarget === "customer") &&
                    <><Grid item xs={12} sm={4} alignItems={"center"} container>
                        <InputLabel htmlFor="qty">Search {namePrefix} </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <UplineSearch popup disabled={ !(selectedTarget === "member" || selectedTarget === "customer") } onlyId={false} noPadding show={true} handleUserSelectFromSearch={handleUserSelectFromSearch}  enableUplineSearch={true}  downlineCustomer={selectedTarget === "customer"}/>
                    </Grid></>}


                     <Grid item xs={12} sm={4} alignItems={"center"} container>
                        <InputLabel htmlFor="qty">{namePrefix}First Name</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        { nameEditable? <TextField fullWidth variant={"outlined"}
                                                   value={_firstName}
                                                   onChange={handleFirstNameChange}
                                                   error={selectedTarget === "guest" && firstNameError}
                                                   helperText={selectedTarget === "guest" && firstNameError}
                                                   inputProps={{min: minTickets, max: maxTickets }}  />: firstName}
                    </Grid>
                    <Grid item xs={12} sm={4} alignItems={"center"} container>
                        <InputLabel htmlFor="qty">{namePrefix}Last Name</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        { nameEditable? <TextField fullWidth variant={"outlined"}
                                                   value={_lastName}
                                                   error={selectedTarget === "guest" && lastNameError}
                                                    helperText={selectedTarget === "guest" && lastNameError}
                                                   onChange={handleLastNameChange}
                                                 />: lastName}
                    </Grid>
                     {(selectedTarget === "guest" ) && <>
                     <Grid item xs={12} sm={4} alignItems={"center"} container>
                        <InputLabel htmlFor="qty">{namePrefix} Zip/Postal Code</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <TextField fullWidth variant={"outlined"}
                                   value={zip}
                                   onChange={(e)=>setZip(e.target.value)}
                                   />
                    </Grid>
                     </>}

                    <Grid item xs={12} sm={4} alignItems={"center"} container>
                        <InputLabel htmlFor="qty">{namePrefix}Email</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        { nameEditable? <TextField fullWidth variant={"outlined"}
                                                   value={_email}
                                                   error={selectedTarget === "guest" && emailError}
                                                   helperText={selectedTarget === "guest" && emailError}
                                                   onChange={handleEmailChange}
                                                  />: email}
                    </Grid>
                    <Grid item xs={12} sm={4} alignItems={"center"} container>
                        <InputLabel htmlFor="qty">Cost Per Ticket</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        ${costPerTicketLoading? <CircularProgress size={16}/>:  costPerTicket}
                    </Grid>
                    <Grid item xs={12} sm={4} alignItems={"center"} container>
                        <InputLabel htmlFor="qty">Qty</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <TextField fullWidth variant={"outlined"} type={"number"}
                                   value={qty}
                                     onChange={(e)=>setQty(e.target.value)}
                        inputProps={{min: minTickets, max: maxTickets }} disabled={minTickets===maxTickets} ></TextField>
                    </Grid>
                    <Grid item xs={12} >
                        <Button variant="contained" disabled={!isValid} color="primary" onClick={addTicket}>{ticketEditing!==false? "Update":"Add"} Ticket</Button>{" "}
                        {ticketsToBuy.length>0 &&  <Button variant={"contained"} color={"secondary"} onClick={cancelAnotherTicket}>Cancel</Button>}
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}>
                    {selectedTarget === "guest" && <>
                        <span >My Guest is an individual attending an event and doesn’t have a Member or Registered Customer Account Number.
                        </span>
                    <ol id="instructionsGuest" >

                        <li>Enter the Guest’s name and email address.</li>
                        <li>Select the number of tickets being purchased.</li>
                        <li>Tap the blue Add Ticket button to move to the payment screen.</li>
                        <li>The Guest’s name will be added to the electronic check-in if physically attending an
                            event.
                        </li>
                        <li>You and your Guest will receive an email with details.</li>
                    </ol></>
                    }

                    {selectedTarget === "member" && <>
                        <ol id="instructionsDLMember">
                            <li>Please enter the Downline Member’s Account Number you’re purchasing the ticket for and
                                select the Member once displayed.
                                This will populate their name and email address. You may edit this information if
                                needed.
                            </li>
                            <li>Select the number of tickets needed.</li>
                            <li>Tap the blue Add Ticket button to move to the payment screen.</li>
                            <li>Your Downline Member will be added to the electronic check-in.</li>
                            <li>You and your Downline Member will receive an email with details.</li>
                        </ol>
                    </>}


                    {selectedTarget === "customer" && <ol id="instructionsRegisteredCustomer">
                        <li>Please enter the Registered Customer’s Account Number you’re purchasing the ticket for and select the Customer once displayed.
                            This will populate their name and email address. You may edit the name and email address if needed.
                        </li>
                        <li>Select the number of tickets needed.</li>
                        <li>Tap the blue Add Ticket button to move to the payment screen.</li>
                        <li>The Registered Customer will be added to the electronic check-in.</li>
                        <li>You and the Registered Customer will receive an email with details.</li>
                    </ol>}
                </Grid>
            </Grid>
            : <Button variant={"contained"} color={"primary"} onClick={addAnotherTicket}>Add Another Ticket</Button>}

        {ticketAdded && <Box width={"100%"}  >
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} >
                <Box >
                    <FormControl fullWidth margin="normal">
                        <InputLabel >{t(`${translationPrefix}:selectpaymentmethod`)}</InputLabel>
                        <Select
                            fullWidth
                            style={{minWidth: "200px"}}
                            value={paymentID}
                            onChange={handleExisting}
                            label={t(`${translationPrefix}:selectpaymentmethod`)}
                        >
                            {[{ paymentID: 0 }, ...cards].map(x => {
                                return <MenuItem key={x.paymentID} value={x.paymentID}>
                                    {
                                        (x.paymentID === 0)
                                            ? t(`${translationPrefix}:selectpaymentmethod`)
                                            :  (x.ewallet)
                                                ? `eWallet (balance: $${x.balance})`
                                                : `${x.type} *******${x.last4} ${showExpDate ? `${x.expMonth.padStart(2, '0')}/${x.expYear}`: ''}`
                                    }
                                </MenuItem>;
                            })}
                        </Select>
                    </FormControl>

                </Box>
                <Box>
                    <FormControlLabel control={<Checkbox />}
                                      value={termsAccepted}
                                      checked={termsAccepted}
                                      onChange={() => { setTermsAccepted(!termsAccepted); }}
                                      label={ <>COVID. {t("iagreetocovid")}</> } />
                </Box>
                <Box>
                    <Button disabled={notSufficientFunds||placingOrder||!termsAccepted}
                            variant="contained" color="primary" onClick={placeOrder}
                            startIcon={ placingOrder && <CircularProgress size={12}/> }
                    >{t("placeOrder")}</Button>
                </Box>
                <br/>
                {(placeOrderError || notSufficientFunds) && <Alert variant={"filled"} severity={"error"}>
                    {placeOrderError ? placeOrderError: notSufficientFunds }
                    {( placeOrderError!=null && notSufficientFunds!=null)  && <><br/>{notSufficientFunds}</>}
                </Alert>}



            </Box>

        </Box>}
    </>
}


export default observer(PurchaseTickets);