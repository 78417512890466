import RestService from "./RestService";

export default class MyFormsService extends RestService {
  constructor() {
    super("/api/myteams");
  }

  getLOSTeams(userID, PVPeriodID, team ) {
    return this.get(`${this.baseUrl}/${userID}`, {PVPeriodID, team});
  }

  searchUsers(  value, enableUplineSearch = false, downlineCustomer= false) {
     return this.post(`${this.baseUrl}/search`, {value, enableUplineSearch, downlineCustomer} );
  }


  listJumps( ) {
    return this.get(`${this.baseUrl}/jump` );
  }

  listRanks( ) {
    return this.get(`${this.baseUrl}/rank` );
  }


  getUserData(userID, PVPeriodID){
    return this.get(`${this.baseUrl}/user/${userID}`, {PVPeriodID});
  }

  getCumulativeUserData(){
    return this.get(`${this.baseUrl}/cumulativeUserData`);
  }

  loadTeamList(userID, PVPeriodID){
    return this.get(`${this.baseUrl}/user/${userID}/teams`, {PVPeriodID});
  }

  loadDashboardDatatByTeam(userID, PVPeriodID){
    return this.get(`${this.baseUrl}/user/${userID}/dashboard`, {PVPeriodID});
  }

  loadDashboardPersonal( PVPeriodID){
    return this.get(`${this.baseUrl}/user/dashboard`, {PVPeriodID});
  }

  listPeriods(userID){
    return this.get(`${this.baseUrl}/periods/${userID}`);
  }
  listUsers(userID, PVPeriodID, sub, status){
    return this.get(`${this.baseUrl}/team/${userID}/users`, {PVPeriodID, sub, status} );
  }


  addWatch(userID){
    return this.post(`${this.baseUrl}/user/${userID}/watch`);
  }

  removeWatch(userID){
    return this.del(`${this.baseUrl}/user/${userID}/watch`);
  }

  addJump(userID){
    return this.post(`${this.baseUrl}/user/${userID}/jump`);
  }

  removeJump(userID){
    return this.del(`${this.baseUrl}/user/${userID}/jump`);
  }

  sendMessages(userIds, from, subject, message){
    return this.post(`${this.baseUrl}/message`, {userIds, from, subject, message});
  }

  selfReportPV(PVPeriodID){
    return this.post(`${this.baseUrl}/user/updateCustomerReqs`, {PVPeriodID});
  }

}
