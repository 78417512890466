import React from 'react';
import { isObservableArray } from 'mobx';

export function formatNumber(v, fractions = 0) {
    if ( v==null ) return "";
    return v.toLocaleString("en-US", {
        minimumFractionDigits: fractions,
        maximumFractionDigits: fractions,
    })
}

export function formatNumberOrZero(v, fractions = 0) {
    if ( v==null ) v=0;

    return v<0? `(${Math.abs(v).toLocaleString("en-US", {
        minimumFractionDigits: fractions,
        maximumFractionDigits: fractions,
    })})` : v.toLocaleString("en-US", {
        minimumFractionDigits: fractions,
        maximumFractionDigits: fractions,
    });
}

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function backOffDelay(baseDelay, counter, maxDelay = 240000, factor = 2) {
    const jitter = 1 + getRandomArbitrary(0, 0.01);

    let delay = Math.min(baseDelay * Math.exp(counter / factor), maxDelay);
    delay = Math.round(delay * jitter);
    //console.log( "delay:",  delay, counter,jitter )
    return delay;
}

export function getUniqKey(item) {
    return (item.tag || item.title ||"").replace(/\//g, "_")
        .replace(/\s+/g, "_")
        .replace(/[^a-zA-Z0-9]/g, "_")
        .replace(/_+/g, "_").toLowerCase();
}


export function extractErrorMessage(error) {
    if (error === false || error === true || error == null) return;
    if (typeof (error) === "string") return error;
    let responseData = error.response && error.response.data && error.response.data;

    if (responseData && responseData.errors && Array.isArray(responseData.errors)) {
        return responseData.errors.map(e => e.messages.join(";")).join("\n");
    }
    if (responseData && responseData.message) {
        return responseData.message;
    }

    return (responseData && responseData.error) || error.message;
}

export function joinPath(...parts) {
    if (!parts) return '';
    let result = '';
    for (let i = 0; i < parts.length; i++) {
        let part = parts[i];
        if (part == null) continue;
        part = part.toString();
        result += part.startsWith('/') && i > 0 ? part.substr(1) : part;

        if (i !== parts.length - 1 && !result.endsWith('/')) result += '/';
    }

    return result;
};

export function parseJwt(token) {
    if (!token) return null;
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


export function cardLogo(cardType) {
    let imgCardLogo = "";

    if (cardType.toLowerCase().includes("visa")) {
        imgCardLogo = "visa";
    } else if (cardType.toLowerCase().includes("mastercard")) {
        imgCardLogo = "mastercard";
    } else if (cardType.toLowerCase().includes("amex")) {
        imgCardLogo = "amex";
    } else if (cardType.toLowerCase().includes("discover")) {
        imgCardLogo = "discover";
    }

    return imgCardLogo ? `https://resources.lifeinfoapp.com/public/logo/${imgCardLogo}.jpg` : null;
};


export function isArray(value) {
    return Array.isArray(value) || isObservableArray(value);
}

export const handleBack = (history)=> {
    let parts = history.location.pathname.split("/")
    if ( parts.length > 1 ) {
        history.push(joinPath(...parts.slice(0, parts.length - 1)))
    }
    else {
        history.push("/")
    }
}

export function acronym(value) {
    if ( !value ) return "";
    return value.split(" ").map(word => word.charAt(0)).join("");
}

export function patchCDN(url) {
    if( url &&  typeof url === "string" && url.indexOf("main.secure.footprint.net")>0 )
        return url.replace("main.secure.footprint.net", "resources.lifeinfoapp.com")
        .replace( /(\w)(\/\/)(\w)/, "$1/$3" )
    return url
}


export function substituteWithComponent(string, key, replacement) {
    if (!string.includes(key)) return string;
    return (
        <>
            {string.substring(0, string.indexOf(key))}
            {replacement}
            {string.substr(string.indexOf(key) + key.length)}
        </>
    );
}

export async function  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getProductImage(item) {
   return  (item.productMedia && item.productMedia[0].imageURL) || item.imageURL || item.productImage || item.bannerImage || item.ImageURL
}