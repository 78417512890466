import React, {useState, useEffect, useCallback, useMemo} from 'react';
import menuJson from "../RoyalStarfish/menu.json";
import {useHistory, useParams} from "react-router";
import {observer} from "mobx-react";
import {useStores} from "../../hooks/use-stores";
import {CircularProgress} from "@material-ui/core";
import Life from "./Life";
import LibraryPack from "../LibraryPack";
import {stringify} from "qs";
import commonStore from "../../stores/commonStore";
import {prepareBannerItemUrl} from "../../utils/menuUtils";
import menu from "../RoyalStarfish/menu.json";
import {flatten} from "lodash";


function Extras({parentMatch}) {
    const {tag} = useParams()
    const history = useHistory()
    const [state, setState] =  useState(0)

    const { authStore, commonStore, libraryStore, shopStore  } = useStores()

    const {checkingPurchased, checkedPurchased} = libraryStore

    const item = useMemo(() => {
        const tagParts = tag.split("/");
        let items = menuJson.find(x=>x.tag==="extras").items
        let processingTag = tagParts.shift()
        let item = null
        do {
            item = items.find(x=>x.tag===processingTag)
            items = item.subItems
            processingTag = tagParts.shift()
        } while (processingTag)

        return item

    }, []);

    useEffect(() => {
        if (item && item.tag !=='accelerator') {
            libraryStore.checkPurchased(item.productSku)
            setState(1)
        }
    },[item])

    useEffect(() => {
        if (checkingPurchased === false && state === 1) {
            setState(2)
        }
    },[checkingPurchased, state])

   const  notPurchased  = useMemo(() => {
       if ( state === 2  )
           return !checkedPurchased[item.productSku]
       return false

   },[checkedPurchased, state, checkingPurchased ])

    useEffect(() => {
        if (state === 2 ) {
            if (!checkedPurchased[item.productSku] ){
                // (async () => {
                //     await shopStore.createCartGuid(true);
                //     await shopStore.addItemToCart({productID: item.mediaID, qty: 1,  cartID: shopStore.userCartGuid, lang: commonStore.language });
                //     history.push( "/starfish/shop/cart")
                // })()
                let { itemUrl,isLoading}  = prepareBannerItemUrl( { url: item.purchaseUrl }, true, authStore)
                history.push( itemUrl)
            }

        }

    }, [state, checkedPurchased]);


    if ( checkingPurchased) return <CircularProgress/>

    if (  item.tag === "accelerator" )
        return <Life/>

    return <>
        {!checkingPurchased && !notPurchased &&  <LibraryPack packIDs={{"en": item.mediaID, "fr": item.mediaID_fr }} />}
    </>
}

export default observer ( Extras);