import React, {useEffect, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {useStores} from "../../../hooks/use-stores";
import {useParams} from "react-router";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    IconButton,
    ListItemText,
    Typography
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {formatNumberOrZero} from "../../../utils/helpers";
import Link from "@material-ui/core/Link";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import StyledTableRow from "../../../components/StyledTableRow";
import moment from "moment";
import yellowText from "../../../themes/yellowText";
import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {greenColor, redColor} from "../../../components/styles";
import Alert from "@material-ui/lab/Alert";
import {Close} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";


const useStyles = makeStyles((theme) => ({
   container: {
       position: "relative",
       ["@media print"]: {
           color: "black",
           "& *": {
               color: "black"
           }
       },
       [theme.breakpoints.down('sm')]: {
           padding: 0
       }

   },
    close:{
        position: "absolute",
        top: -10,
        right: -15
    }
}));

function SubSection({title}) {
    return <Grid item xs={12}>
        <Typography variant={"subtitle1"}
                    style={{marginTop: 20, paddingBottom: 0, borderBottom: "1px solid white"}}>{title}</Typography>
    </Grid>
}

export default observer(function TransactionInfo({transactionDetails, autoPrint, onClose}) {

    const {orderID} = useParams();
    const classes = useStyles();

    const {t} = useTranslation("transactions");
    const {authStore, transactionsStore} = useStores();
    const {currentUser} = authStore;

    useEffect(() => {
        if ( orderID )
            transactionsStore.loadTransactionDetails(orderID);
    }, [orderID]);


    const {transactionDetailsLoading, detailError} = transactionsStore;

    if ( !transactionDetails ){
        transactionDetails = transactionsStore.transactionDetails;
    }

    useEffect(()=>{
        if ( autoPrint &&  !transactionDetailsLoading && transactionDetails){
            window.setTimeout(()=>{
                window.print();
            }, 200);
        }

    },[transactionDetailsLoading, transactionDetails])

    return <Container maxWidth={"md"} className={classes.container }>
        <CssBaseline/>
        {onClose  && <Fab size={"small"} className={classes.close} onClick={onClose}><Close/></Fab>}
        <Box>
            {detailError && <Alert severity="error" variant={"filled"}>{detailError}</Alert>}
            {(transactionDetailsLoading===null || transactionDetailsLoading) && <Box minHeight={500} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <CircularProgress size={50}/>
            </Box>}
            {transactionDetails &&  <Grid container spacing={2}>
                    <Grid item xs={6} alignItems={"center"}>
                        <img src={"/img/RoyalLogo.png"} width={50}/>
                    </Grid>
                    <Grid container item xs={6} alignItems={"center"} justifyContent={"flex-end"}>
                        <Typography variant={"h6"}>Order Confirmation: {transactionDetails.orderID || orderID}</Typography>
                    </Grid>
                    {authStore.country === "CA" &&  <Grid xs={12} item>
                        <Typography variant={"body1"}>GST # - 850746507 RT0001</Typography>
                    </Grid> }
                    <Grid xs={12} item>
                        <Typography variant={"body1"}>{t("thanks")}</Typography>
                        <Typography variant={"body1"}>{t("summary", {orderID: transactionDetails.orderID || orderID, date: moment(transactionDetails.orderDetails.OrderDate).format("YYYY-MM-DD h:mm:ss A") } )}</Typography>
                    </Grid>
                    <Grid xs={12} item>
                        <Typography variant={"body1"}>{t("details_below")}</Typography>
                       </Grid>
                    {
                        (transactionDetails.orderDetails.OrderStatusID  === 110 || transactionDetails.orderDetails.OrderStatusID  === 111 ) &&
                        <ThemeProvider theme={yellowText}>
                            <Grid container item sm={12} direction="column" alignItems={"flex-start"}>
                            { transactionDetails.orderDetails.OrderStatusID  === 110  && <Typography color={"primary"}>This order was fully refunded on {moment(transactionDetails.orderDetails.RefundDate).format("YYYY-MM-DD")}</Typography>}
                            { transactionDetails.orderDetails.OrderStatusID  === 111  && <Typography color={"primary"}>This order was partially refunded  on {moment(transactionDetails.orderDetails.RefundDate).format("YYYY-MM-DD")}</Typography>}
                            </Grid>
                        </ThemeProvider>
                    }
                    <SubSection title={t("order_summary")}/>
                    <Grid xs={12} item container spacing={1}>
                        <Grid item xs={6} sm={2}>
                            <Typography variant={"body1"}>{t("subtotal")}:</Typography>
                        </Grid>
                        <Grid item xs={6} sm={10}>
                            <Typography
                                variant={"body1"}>${formatNumberOrZero(transactionDetails.orderDetails.Subtotal, 2)}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Typography variant={"body1"}>{t("tax")}:</Typography>
                        </Grid>
                        <Grid item xs={6} sm={10}>
                            <Typography
                                variant={"body1"}>${formatNumberOrZero(transactionDetails.orderDetails.Tax, 2)}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Typography variant={"body1"}>{t("shipping")}:</Typography>
                        </Grid>
                        <Grid item xs={6} sm={10}>
                            <Typography
                                variant={"body1"}>${formatNumberOrZero(transactionDetails.orderDetails.Shipping, 2)}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Typography variant={"body1"}>{t("total")}:</Typography>
                        </Grid>
                        <Grid item xs={6} sm={10}>
                            <Typography
                                variant={"body1"}>${formatNumberOrZero(transactionDetails.orderDetails.GrandTotal, 2)}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                        </Grid>
                        <Grid item xs={6} sm={10}>
                            <Typography variant={"body1"}>{transactionDetails.orderDetails.TotalPV} PV</Typography>
                        </Grid>
                    </Grid>
                    <SubSection title={t("details")}/>

                    <Table size={"small"} border={0}>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell/>
                                <TableCell align="right">{t("qty")}</TableCell>
                                <TableCell align="right">{t("cost")}</TableCell>
                                <TableCell align="right">{t("amount")}</TableCell>
                                <TableCell align="right">{t("pv")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactionDetails.products.map((d, i) => {
                                return <TableRow key={i}>
                                    <TableCell>
                                        {d.ImageUrl && <Avatar variant={"rounded"} src={d.ImageUrl} alt={d.ProductName}
                                                               style={{width: "75px", height: "75px"}}/>}
                                    </TableCell>
                                    <TableCell>
                                        <ListItemText primary={d.ProductName}
                                                      secondary={
                                                          `${d.SKU ? `SKU: ${d.SKU}` : ""}
                                                                                              | Format: ${d.IsDigital ? "Digital" : "Physical"} 
                                                                                              | Price: $${d.UnitCost.toFixed(2)}
                                                                                                ${currentUser.isMember ? `PV: ${d.UnitPV.toFixed(2)}` : ''}`


                                                      }/>
                                    </TableCell>
                                    <TableCell align="right">
                                        {d.Quantity}
                                    </TableCell>
                                    <TableCell align="right">
                                        ${d.UnitCost.toFixed(2)}
                                    </TableCell>
                                    <TableCell align="right">
                                        ${(d.TotalCost).toFixed(2)}
                                    </TableCell>
                                    <TableCell align="right">
                                    {d.TotalPV.toFixed(2)}
                                </TableCell>
                                </TableRow>;
                            })}
                        </TableBody>
                    </Table>

                    <SubSection title={t("shippingInfo")}/>

                    <Grid xs={12} item container spacing={0}>
                        <Grid item xs={12}>
                            <Typography
                                variant={"body1"}>{transactionDetails.orderDetails.ShippingAddressName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant={"body1"}>{transactionDetails.orderDetails.ShippingAddress1}</Typography>
                        </Grid>
                        {transactionDetails.orderDetails.ShippingAddress2 && <Grid item xs={12}>
                            <Typography
                                variant={"body1"}>{transactionDetails.orderDetails.ShippingAddress2}</Typography>
                        </Grid>}
                        <Grid item xs={12}>
                            <Typography
                                variant={"body1"}>{transactionDetails.orderDetails.ShippingCity} {transactionDetails.orderDetails.ShippingState} {transactionDetails.orderDetails.ShippingZip}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>{transactionDetails.orderDetails.ShippingCountry}</Typography>
                        </Grid>
                        {(transactionDetails.tracking && transactionDetails.tracking.length>0) && <Grid container item xs={12}>
                            <Box display={"grid"} gridTemplateColumns={"auto 1fr"} gridRowGap={3} gridColumnGap={8}>
                                <Box><Typography>Tracking#</Typography></Box>
                                {(transactionDetails.tracking || []).map((t, i) => {
                                        return <Link style={{gridColumnStart: 2, alignSelf: "center"}} key={i}
                                                     href={t.TrackingLink}
                                                     target={"_blank"}> {t.TrackingNumber}</Link>

                                    })}
                            </Box>
                        </Grid>}

                    </Grid>

                    <SubSection title={t("billingInfo")}/>

                    <Grid xs={12} item container spacing={0}>
                        {transactionDetails.orderDetails.AmountEWallet>0
                            ? <Grid item xs={12}>
                                <Typography
                                    variant={"body1"}>{t("paid_ewallet")}</Typography>
                            </Grid>
                            : <Grid item xs={12}>
                                <Typography
                                    variant={"body1"}>{t("paid_cc")}  ****{transactionDetails.orderDetails.Last4}</Typography>
                            </Grid>}

                        <Grid item xs={12}>
                            <Typography
                                variant={"body1"}>{t("lifeNo")} {transactionDetails.orderDetails.UserID}</Typography>
                        </Grid>

                    </Grid>


                </Grid>}
        </Box>
    </Container>

})