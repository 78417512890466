import BaseService from "./BaseService";

class RestService extends BaseService {

    constructor(baseUrl) {
        super()
        this.baseUrl = baseUrl
    }

    getId(entity, id ) {
        if ( id ) return id
        if ( typeof  entity === "string" ) return entity
        if ( typeof  entity === "number" ) return entity
        return entity && (entity.id || entity._id)
    }

    create(entity, options) {
        return this.post(this.baseUrl, entity, options)
    }

    fetch(entityId) {
        return this.get(`${this.baseUrl}/${entityId}`)
    }

    deepFetch(entityIds) {
        if(Array.isArray(entityIds)) {
            let url = ''
            entityIds.forEach(entityId => {
                url += `/${entityId}`
            })
            return this.get(`${this.baseUrl}${url}`)
        }
        return this.get(`${this.baseUrl}/${entityIds}`)
    }

    list(params) {
        return this.get(`${this.baseUrl}`, params)
    }

    update(entity, id) {
        return this.put(`${this.baseUrl}/${this.getId(entity, id)}`, entity)
    }

    delete(entityId, params) {
        return this.del(`${this.baseUrl}/${this.getId(entityId)}`, params)
    }



}

export default RestService